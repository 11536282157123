<template>
  <div v-if="this.showPlus">
    <div>
      <div v-if="img" class="product-img-wrap">
        <div
          :style="{ 'background-image': `url(${img}` }"
          :alt="title"
          class="product-img"
        ></div>
      </div>
      <div class="addon-main d-flex">
        <div class="flex-1">
          <h4 class="no-margin">{{ title }}</h4>
          <p
            class="item-subtitle"
            @click="extractURLFirst(subtitle) ? window.open(extractURLFirst(subtitle)) : {}"
            :class="[extractURLFirst(subtitle) ? 'clickable' : null]"
          >
            {{ subtitle }}
          </p>
          <h3 v-if="!paid" class="no-margin">
            {{ formatMoney(price) }}
          </h3>
          <h3 v-if="paid" class="no-margin" style="color: #098255">PAID</h3>
          <SuperInput
            class="input-underline w-50 no-margin"
            v-if="requireSizes"
            :field="field"
            v-model="notesLocal"
            @input="handleNotes"
          />
        </div>
        <div class="quantity flex-0 d-flex-column">
          <div class="flex-1" />
          <div class="quantity-inner d-flex flex-0">
            <img
              v-if="!this.mandatory && this.showPlus"
              src="@/assets/icons/minus.svg"
              @click="changeQuantity(-1)"
              alt="increment"
              class="quantity-icon"
            />
            <p class="margin-lr">{{ qty }}</p>
            <img
              v-if="!this.mandatory && this.showPlus"
              src="@/assets/icons/plus.svg"
              @click="changeQuantity(1)"
              alt="decrement"
              class="quantity-icon"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatMoney } from "@/utils/money";
import { extractURLFirst } from "@/utils/misc";
import SuperInput from "@/components/form/fields/SuperInput.vue";

export default {
  name: "AddonItem",
  components: { SuperInput },
  props: [
    "product",
    "current",
    "title",
    "subtitle",
    "id",
    "price",
    "qty",
    "requireSizes",
    "notes",
    "img",
    "mandatory",
    "seasonPass",
    "seasonPassFrom",
    "seasonPassTo",
  ],
  methods: {
    setQuantity(val) {
      this.$emit("update:qty", val);
      this.$emit("recalculate");
    },
    changeQuantity(val) {
      let newVal = this.qty + val;
      if (newVal < 0) newVal = 0;
      this.$emit("update:qty", newVal);
      this.$emit("recalculate");
    },
    handleNotes(val) {
      this.$emit("update:notes", val);
    },
  },
  computed: {
    paid() {
      if (this.current && this.current.profile) {
        if (Array.isArray(this.current.profile.seasonPasses)) {
          if (this.current.profile.seasonPasses.includes(this.id)) {
            return true;
          }
        }
      } else {
        return false;
      }
    },
  },
  mounted() {
    // we have a flag to simplify whether the skip button should be hidden
    let flag = false;
    if (this.mandatory) {
      this.setQuantity(1);
      flag = true;
    }
    // Get the current Date
    const currentDate = new Date();
    const timestampDate = currentDate.getTime();
    if (this.seasonPassFrom && this.seasonPassTo && this.seasonPass) {
      if (timestampDate >= this.seasonPassFrom && timestampDate <= this.seasonPassTo) {
        this.setQuantity(1);
        this.showPlus = true;
      } else {
        this.setQuantity(0);
        this.showPlus = false;
      }
      if (!this.mandatory) flag = false;
      // skip button active
      else if (this.mandatory) flag = true;
    }
    // if (this.seasonPass && !this.seasonPassFrom && !this.seasonPassTo) {
    //   this.setQuantity(1);
    // }
    if (this.current.profile) {
      if (Array.isArray(this.current.profile.seasonPasses)) {
        if (this.current.profile.seasonPasses.includes(this.id)) {
          this.setQuantity(0);
          flag = false;
        }
      }
    }

    if (flag) {
      this.$emit("addonMandatory");
    }
  },
  data() {
    return {
      showPlus: true,
      extractURLFirst,
      formatMoney,
      notesLocal: this.notes,
      window,
      field: {
        label: "Size",
        errors: [],
        component: "GroupedSelect",
        options: [
          {
            label: "Kids",
            options: [
              {
                label: "4",
                value: "kid-4",
              },
              {
                label: "6",
                value: "kid-6",
              },
              {
                label: "8",
                value: "kid-8",
              },
              {
                label: "10",
                value: "kid-10",
              },
              {
                label: "12",
                value: "kid-12",
              },
              {
                label: "14",
                value: "kid-14",
              },
              {
                label: "16",
                value: "kid-16",
              },
            ],
          },
          {
            label: "Adults",
            options: [
              {
                label: "XS",
                value: "adult-xs",
              },
              {
                label: "Small",
                value: "adult-s",
              },
              {
                label: "Medium",
                value: "adult-m",
              },
              {
                label: "Large",
                value: "adult-l",
              },
              {
                label: "XL",
                value: "adult-xl",
              },
              {
                label: "2XL",
                value: "adult-2xl",
              },
              {
                label: "3XL",
                value: "adult-3xl",
              },
              {
                label: "4XL",
                value: "adult-4xl",
              },
              {
                label: "5XL",
                value: "adult-5xl",
              },
              {
                label: "6XL",
                value: "adult-6xl",
              },
            ],
          },
          {
            label: "Ladies",
            options: [
              {
                label: "6",
                value: "ladies-6",
              },
              {
                label: "8",
                value: "ladies-8",
              },
              {
                label: "10",
                value: "ladies-10",
              },
              {
                label: "12",
                value: "ladies-12",
              },
              {
                label: "14",
                value: "ladies-14",
              },
              {
                label: "16",
                value: "ladies-16",
              },
              {
                label: "18",
                value: "ladies-18",
              },
              {
                label: "20",
                value: "ladies-20",
              },
              {
                label: "22",
                value: "ladies-22",
              },
              {
                label: "24",
                value: "ladies-24",
              },
            ],
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.product-img-wrap {
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  border: 5px solid #fff;
  -webkit-box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  border-radius: 25%;
  width: 7.5rem;
  height: 7.5rem;

  .product-img {
    background-size: contain;
    background-position: center;
    height: 100% !important;
  }
}
.quantity-icon {
  width: 35px;
  cursor: pointer;
}

.margin-lr {
  margin: auto 10px auto;
}

.addon {
  position: relative;
  padding-top: 20px;

  .addon-main {
    position: relative;
    padding-bottom: 20px;
    padding-top: 20px;

    .item-subtitle {
      margin-top: 5px;
      margin-bottom: 6px;
      font-size: 0.875em;
      color: #424242;
    }
  }
}
@media only screen and (min-width: 768px) {
  .addon {
    padding-top: 0;
    min-height: 9.5rem;
  }
  .product-img-wrap {
    position: absolute;
    left: calc(-7.5em - 15px);
    top: 0;
    bottom: 0;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
</style>
