<template>
  <div>
    <div v-if="products" class="d-flex-column align-center main">
      <div class="heading">
        <h3 class="no-margin head">Purchase</h3>
      </div>
      <el-card>
        <div class="mw-400">
          <div class="main">
            <!-- <div v-for="type in types" :key="type" class="m-0"> -->
            <AddonItem
              v-for="product of products"
              @addonMandatory="hideSkip = true"
              :key="product._id"
              :requireSizes="product.requireSizes"
              :id="product._id"
              :title="product.name"
              :price="product.price"
              :subtitle="product.description"
              :qty.sync="product.qty"
              :notes.sync="product.notes"
              :mandatory="product.mandatory"
              :seasonPass="product.seasonPass"
              :seasonPassFrom="product.seasonPassFrom"
              :seasonPassTo="product.seasonPassTo"
              :current="current"
              class="addon"
            />
          </div>
          <!-- </div> -->
        </div>
      </el-card>

      <el-card class="total-card">
        <div class="d-flex total-card-inner">
          <div>
            <h4 class="no-margin">Total</h4>
          </div>
          <div class="flex-last">
            <h3 class="no-margin">{{ subtotal }}</h3>
          </div>
        </div>
      </el-card>

      <div class="align-text-center">
        <el-button type="primary" class="full-width mw-400" @click="submitForm"
          >Add Items</el-button
        >
      </div>
      <div v-if="!hideSkip" class="align-text-center">
        <el-button class="full-width mw-400 button-invisible" @click="skipForm">Skip</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import AddonItem from "@/components/addon-item/AddonItem.vue";
import { formatMoney } from "@/utils/money";
import { endpointParams } from "@/utils/constants/api";
import { regoAddonSubtotal } from "@/utils/registration/addon";
import msg from "@/utils/constants/msg";

export default {
  name: "TeamChooseAddon",
  components: {
    AddonItem,
  },

  computed: {
    ...mapGetters("registrationTeams", ["current"]),
    ...mapGetters("registration", ["completed"]),

    subtotal() {
      return formatMoney(regoAddonSubtotal({ priceItem: this.localPriceItem }));
    },
    types() {
      // eslint-disable-next-line prefer-const
      let types = [];
      Object.entries(this.localPriceItem.pricing).forEach(
        // eslint-disable-next-line no-unused-vars
        ([category, item]) => {
          types.push(category);
        }
      );
      return types;
    },
  },
  created() {
    // Set Current Price Item
    this.localPriceItem = cloneDeep(this.current.price);

    /* ----- START POPULATE ADDON ITEMS  ---- */
    // eslint-disable-next-line prefer-const
    let types = [];
    Object.entries(this.localPriceItem.pricing).forEach(
      // eslint-disable-next-line no-unused-vars
      ([category, item]) => {
        types.push(category);
      }
    );
    types.forEach((type) => {
      const current = this.localPriceItem.pricing[type];
      if (current && current.saleableItems && current.saleableItems.length > 0) {
        current.saleableItems.forEach((item) => {
          if (!item.qty) this.$set(item, "qty", 0);
          if (!item.notes) this.$set(item, "notes", "");
        });
      }
    });
    /* ----- END POPULATE ADDON ITEMS  ---- */
    /* ---- CHECK PRODUCTS ---- */
    const products = [];
    if (!this.localPriceItem.pricing || this.localPriceItem.pricing.length < 1) return products;

    for (const type of types) {
      if (
        !this.localPriceItem.pricing[type].saleableItems ||
        this.localPriceItem.pricing[type].saleableItems.length < 1
      )
        continue;
      for (const product of this.localPriceItem.pricing[type].saleableItems) {
        if (this.checkExistingSeasonPasses(product)) {
          //   console.log(`failed seasonpasses ${product.name}`);
          continue;
        }
        if (!this.checkProductEntity(product)) {
          // console.log(`failed product entity ${product.name}`);
          continue;
        }
        if (!this.checkCompletedReg(product, this.current)) {
          // console.log(`failed completed reg ${product.name}`);
          continue;
        }
        products.push(product);
      }
    }
    if (products.length === 0) {
      this.skipForm();
    }
    this.products = products;
  },
  data() {
    return {
      hideSkip: false,
      products: [],
      formatMoney,
      endpointParams,
      localPriceItem: {},
    };
  },
  methods: {
    ...mapMutations("root", ["LOADING"]),
    ...mapActions("views", ["triggerEvent"]),
    ...mapActions("registrationTeams", ["submit", "updateCurrent"]),
    ...mapMutations("views", ["PUSH_NOTIFICATION"]),

    checkExistingSeasonPasses(product) {
      if (
        this.current.profile &&
        this.current.profile.seasonPasses &&
        this.current.profile.seasonPasses.length > 0
      ) {
        if (this.current.profile.seasonPasses.includes(product._id)) return true;
        else {
          // Get the current Date
          const currentDate = new Date();
          const timestampDate = currentDate.getTime();
          if (timestampDate >= product.seasonPassFrom && timestampDate <= product.seasonPassTo)
            return false;
          else return true;
        }
      }
    },
    checkCompletedReg(product, current) {
      let flag = true;
      if (!this.completed) return flag;
      for (const rego of this.completed) {
        if (rego.id !== current.id) continue;
        // check season passes
        if (rego.profile && rego.profile.seasonPasses && rego.profile.seasonPasses.length > 0) {
          if (rego.profile.seasonPasses.includes(product._id)) {
            flag = false;
          }
        }

        // check product ids
        if (rego.priceItem && rego.priceItem.pricing) {
          // loop thru all the types
          for (const typ of ["national", "state", "region", "association", "competition", "club"]) {
            if (rego.priceItem.pricing[typ] && rego.priceItem.pricing[typ].saleableItems) {
              for (const item of rego.priceItem.pricing[typ].saleableItems) {
                if (item.seasonPass && item._id === product._id) {
                  flag = false;
                }
              }
            }
          }
        }
      }
      return flag;
    },
    checkProductEntity(product) {
      // typical data shape

      const entity = this.current.entity;

      // check the product applies to this entity
      let flag = true;
      if (
        product.meta &&
        product.meta.entityType &&
        product.meta.entityIds &&
        product.meta.entityIds.length > 0
      ) {
        // does any of the org tree match

        for (const typ of ["national", "state", "region", "association", "club"]) {
          if (product.meta && product.meta.entityType === typ) {
            // need to check the entity includes this id

            if (!entity.orgtree[typ]) continue;
            flag = false;
            if (product.meta.entityIds.includes(entity.orgtree[typ]._id)) flag = true;
          }
        }

        // loop thru ids and check
      }
      return flag;
    },
    submitForm() {
      let throwError = false;
      let seasonPassesSet = new Set([]);
      if (this.current.profile && this.current.profile.seasonPasses)
        seasonPassesSet = new Set(this.current.profile.seasonPasses);
      this.types.forEach((type) => {
        this.localPriceItem.pricing[type].saleableItems.forEach((product) => {
          if (product.requireSizes && product.qty > 0 && product.notes === "") {
            throwError = true;
          }
          if (product.seasonPass) {
            seasonPassesSet.add(product._id);
          }
        });
      });
      if (throwError) {
        this.PUSH_NOTIFICATION({
          msg: msg.error.sizeValidationError,
          route: this.$route.name,
          type: "warning",
        });
        window.scrollTo(0, 0);
      } else {
        const seasonPassesUpdate = Array.from(seasonPassesSet);
        this.updateCurrent({ price: this.localPriceItem, seasonPasses: seasonPassesUpdate });
        this.submitRegoAndNext();
      }
    },
    skipForm() {
      this.submitRegoAndNext();
    },
    submitRegoAndNext() {
      this.$router.push({ name: "teampayment" });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  text-align: left;
}

.addon:not(:last-child) {
  border-bottom: 1px solid lightgrey;
}

.total-card {
  width: 100vw;

  .total-card-inner {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
